import { IconProps } from 'components/icons/types';

export const LightningIcon = (props: IconProps) => {
  const { width = 12, height = 14, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 12 14" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33349 8.625L1.37976 8.1375C1.10172 8.105 0.920993 7.95875 0.837581 7.69875C0.754169 7.43875 0.812557 7.21667 1.01275 7.0325L7.83584 0.6625C7.89145 0.608333 7.95818 0.567708 8.03603 0.540625C8.11388 0.513542 8.21954 0.5 8.353 0.5C8.57543 0.5 8.74504 0.592083 8.86181 0.77625C8.97859 0.960417 8.98137 1.15 8.87015 1.345L6.66808 5.375L10.6218 5.8625C10.8998 5.895 11.0806 6.04125 11.164 6.30125C11.2474 6.56125 11.189 6.78333 10.9888 6.9675L4.16572 13.3375C4.11011 13.3917 4.04338 13.4323 3.96553 13.4594C3.88768 13.4865 3.78202 13.5 3.64856 13.5C3.42613 13.5 3.25653 13.4079 3.13975 13.2238C3.02297 13.0396 3.02019 12.85 3.13141 12.655L5.33349 8.625Z"
        fill="currentColor" />
    </svg>
  )
};
