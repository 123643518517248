import { IconProps } from 'components/icons/types';

export const LightningOutlineIcon = (props: IconProps) => {
  const { width = 18, height = 23, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 18 23" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99577 14.0039L2.07077 13.2539C1.65411 13.2039 1.38327 12.9789 1.25827 12.5789C1.13327 12.1789 1.22077 11.8372 1.52077 11.5539L11.7458 1.75391C11.8291 1.67057 11.9291 1.60807 12.0458 1.56641C12.1624 1.52474 12.3208 1.50391 12.5208 1.50391C12.8541 1.50391 13.1083 1.64557 13.2833 1.92891C13.4583 2.21224 13.4624 2.50391 13.2958 2.80391L9.99577 9.00391L15.9208 9.75391C16.3374 9.80391 16.6083 10.0289 16.7333 10.4289C16.8583 10.8289 16.7708 11.1706 16.4708 11.4539L6.24577 21.2539C6.16244 21.3372 6.06244 21.3997 5.94577 21.4414C5.82911 21.4831 5.67077 21.5039 5.47077 21.5039C5.13744 21.5039 4.88327 21.3622 4.70827 21.0789C4.53327 20.7956 4.52911 20.5039 4.69577 20.2039L7.99577 14.0039Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
};
